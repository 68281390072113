
.home-h2{
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.43;
    margin-bottom: 12px;
    min-height: 25px;

    @media (max-width: 1159px){
        font-size: 30px;
    }
    @media (max-width: 767px){
        font-size: 26px;
        margin-bottom: 6px;
    }
    &:lang(hy){
        font-weight: 600;
        font-size: 30px;

        @media (max-width: 1159px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 22px;
        }
    }
}
.basic-header{
    padding-left: 9vw;
    font-size: clamp(12px, 1.17vw, 18px);

    @media (max-width: 991px) {
        padding-left: 4vw;
        font-size: clamp(9px, 1.17vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
        position: relative;
    }
}
.basic-header-text{
    width: 49.6%;
    padding-right: 1.6em;
    padding-top: 1em;
    padding-bottom: 1em;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 4em;
        margin-top: 4em;
        padding: 0 20px;
        z-index: 1;
    }
    h1{
        font-weight: 400;
        margin-bottom: .87em;
        font-size: 2.8em;
        line-height: 1.22;
        min-height: 25px;

        &:lang(hy){

            @media (min-width: 992px){
                font-size: 2.6em;
            }
            @media (max-width: 767px){
                font-size: 23px;
            }
        }
        @media (max-width: 767px){
            font-size: 28px;
        }
    }
    > p{
        margin-bottom: 2.1em;
        font-size: 1.185em;
        line-height: 1.35;
        max-width: 24em;
        min-height: 22px;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 18px;
            max-width: inherit;
            margin-bottom: 1.5em;
        }
    }
    .basic-landing-2-container{
        padding: 40px 0;
    }
    .row{
        padding: 10px 0;
        margin-left: -10px;
        margin-right: -10px;
    }
    .container{
        padding: 10px 0;
    }
}
.basic-head-title-img{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: auto;
    width: 12em;

    @media (max-width: 767px){
        display: none;
    }
}
.basic-header-img{
    width: 50.4%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        height: 37.55vw;
        object-fit: cover;

        @media (max-width: 767px){
            height: 100%;
        }
    }
}
.basic-section-2{
    padding: 36px 0;

    @media (max-width: 767px){
        padding: 24px 0 6px;
    }
}
.basic-section-2-container{
    max-width: 84%;
    margin: 0 auto;
    padding: 12px 0;

    @media (max-width: 991px){
        max-width: 100%;
    }
}
.basic-section-2-left{
    width: 460px;

    @media (max-width: 991px){
        max-width: 380px;
    }
    @media (max-width: 767px){
       margin: 0 auto;
        order: 1;
    }
}
.section-2-basic-oval{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-height: 100%;
    width: 42%;
    height: auto;

    @media (max-width: 767px){
        width: 100%;
        top: 38%;
    }
}
.basic-section-2-block{
    padding: 12px 24px;
}
.basic-section-2-item{
    background-color: #ffffff;
    margin: 12px 0;
    box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
    padding: 24px;
    min-height: 230px;
    width: calc(50% - 12px);

    @media (max-width: 767px){
        padding: 12px;
        min-height: 158px
    }
    &:lang(hy){

        @media (min-width: 768px) and (max-width: 991px){
            padding: 15px;
        }
    }
    > b{
        font-size: 2.3em;
        margin-bottom: .5em;
        display: block;
        min-height: 22px;

        @media (max-width: 767px){
            font-size: 1.8em;
        }
    }
    > p{
        font-size: 1.12em;
        min-height: 22px;

        @media (max-width: 767px){
            font-size: .94em;
        }
    }
}
.basic-section-2-item-yellow{
    background-color: #F0AE00;
    color: #ffffff;
}
.basic-section-2-right{
    width: 44.5%;
    max-width: 700px;
    margin-left: auto;
    padding: 24px;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 0 24px;
    }

    > h2{
        font-weight: 400;
        margin-bottom: 24px;
        font-size: 36px;
        text-align: right;
        min-height: 25px;

        @media (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            text-align: center;
            margin-bottom: 16px;
        }
        &:lang(hy){
            font-weight: 600;
            font-size: 30px;

            @media (max-width: 1159px){
                font-size: 26px;
            }
            @media (max-width: 767px){
                font-size: 22px;
            }
        }
    }
    > p{
        font-size: 1.185em;
        min-height: 20px;

        @media (max-width: 991px){
            font-size: 1em;
            margin-bottom: 12px;
        }
        &:lang(hy){

            @media (min-width: 992px){
                font-size: 1.1em;
            }
        }
    }
}
.basic-section-3{
    padding: 48px 0 36px;

    @media (max-width: 767px){
        padding: 24px 0 12px;
    }
}
.basic-section-3-container{
    max-width: 1092px;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
}
.basic-section-4{
    padding: 48px 0 36px;

    @media (max-width: 767px) {
        padding: 24px 0 12px;
    }
}
.basic-section-4-container-1{
    max-width: 1140px;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px){
        max-width: 768px;
    }
}
.basic-section-4-container{
    margin: 0 -24px;
    padding: 12px 0;

    @media (max-width: 767px){
        margin: 0;
    }
}
.basic-section-4-item{
    width: 33.33%;
    padding: 12px 24px;
    text-align: center;

    @media (max-width: 767px) {
        width: 100%;
        padding: 12px 0;
    }
    > img{
        width: 213px;
        height: 213px;
        border-radius: 50%;
        margin-bottom: 20px;
        object-fit: cover;

        @media (max-width: 1159px){
            width: 170px;
            height: 170px;
        }
        @media (max-width: 767px){
            width: 232px;
            height: 232px;
        }
    }
    > h3{
        font-size: 20px;
        margin-bottom: .5em;
        min-height: 20px;
    }
    > h4{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 1.15em;
        min-height: 20px;
    }
    > p{
        font-size: 17px;
        line-height: 1.35;
        margin-bottom: 0;
        min-height: 20px;

        @media (max-width: 1159px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.basic-section-5{
    padding: 48px 0 48px;

    @media (max-width: 767px){
        padding: 24px 0 12px;
    }
}
.basic-section-5-container-1{
    max-width: 1140px;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;;

    @media (max-width: 1159px){
        max-width: 768px;
    }
}
.basic-section-5-container{
    column-gap: 6em;
    column-count: 2;
    max-width: 1108px;
    margin: 0 auto;
    padding: 24px 0;

    @media (max-width: 1159px){
        column-gap: 3em;
    }
    @media (max-width: 767px){
        column-count: 1;
        column-gap: 0;
    }
}
.basic-section-5-item{
    border-bottom: solid 5px #F0AE00;
    padding-bottom: 16px;
    margin-bottom: 32px;
    break-inside: avoid-column;

    @media (max-width: 767px){
        margin-bottom: 50px;
    }
    > p{
        font-size: 1.12em;
        line-height: 1.6;
        margin-bottom: 1.45em;
        min-height: 20px;

        @media (min-width: 768px) and (max-width: 1159px){
            font-size: 1em;
        }
        @media (max-width: 767px){
            font-size: 1.03em;
        }
    }
}
.basic-section-5-item-author{
    padding: 8px 0;
    display: flex;
    align-items: center;

    > img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 13px;
        border-radius: 50%;
    }
    > span{
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        min-height: 20px;

        b{
            width: 100%;
            min-height: 20px;
            display: inline-block;
        }
    }
}
.quot-student{
    position: absolute;
    left: 0;
    top: -8%;
    z-index: -1;
    width: 30%;

    @media (max-width: 767px){
        width: 100%;
        top: 0;
    }
}
